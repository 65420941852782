import * as React from 'react';

import styled from 'styled-components';

import { theme } from '../../../../styles/2020/theme';

import { H2, P } from '../../common/Primitives';
import { Column } from '../../layout/Column';
import { FluidGrid } from '../../layout/FluidGrid';
import { FullWidthBackground } from '../../layout/FullWidthBackground';

type ResourceBlockProps = {
  title: string;
  text?: string;
  variant?: 'narrow' | 'default';
  colorVariant?: 'inherit' | 'dark' | 'beige';
};

export function ResourceBlock({
  title,
  text,
  variant = 'default',
  colorVariant = 'inherit',
  children,
}: React.PropsWithChildren<ResourceBlockProps>) {
  return (
    <FullWidthBackground
      forwardedAs="section"
      invertColors={colorVariant === 'dark'}
      secondaryColor={colorVariant === 'beige' ? theme.colors.beige : theme.colors.white}
    >
      <Container width={variant === 'narrow' ? theme.space.measure : theme.space.siteWidth}>
        <Column space={theme.space.m}>
          <Column space={theme.space.xxxs}>
            <H2>{title}</H2>

            {text && <P>{text}</P>}
          </Column>

          <FluidGrid max="250px" justify="flex-start" space={theme.space.l}>
            {children}
          </FluidGrid>
        </Column>
      </Container>
    </FullWidthBackground>
  );
}

const Container = styled.div<{ width: string }>`
  width: 100%;
  max-width: ${(props) => props.width};
`;
