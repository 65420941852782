import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { ResourceBlock } from 'stile-shared/src/components/2020/blocks/ResourceBlock';
import { Card } from 'stile-shared/src/components/2020/common/Card';

import { getPageData } from 'utils/getPageData';

import { theme } from 'styles/theme';

import { PageLayout } from 'templates/2020/PageLayout';

import { CtaBlock } from 'components/blocks/CtaBlock';

export { Head } from 'templates/2020/Head';

function ResourcesPage(props: PageProps) {
  const { hero } = getPageData(props.location.pathname);

  return (
    <PageLayout {...props}>
      <HeroBlock
        {...hero}
        image={
          <StaticImage
            loading="eager"
            aspectRatio={2.37}
            src="../../../../stile-shared/assets/images/resources/cover_resources_us.png"
            alt="A collection of Stile teaching resource books on a table"
          />
        }
      />

      <ResourceBlock
        title="Useful links"
        text="Get to know the basics of Stile, enrolling students, browsing the library
              and creating your own lessons."
      >
        <Card
          space={theme.space.xxs}
          title="Getting Started"
          text="Brand new to Stile? Have a look at these quick tutorials."
          url="https://stileapp.com/go/GetStarted1"
          image={
            <StaticImage
              src="../../../../stile-shared/assets/images/resources/getting_started.png"
              alt="A closeup of a hand poised above a laptop"
            />
          }
        />

        <Card
          space={theme.space.xxs}
          title="Stile Library"
          text="Explore the range of interactive, relevant and engaging science lessons."
          url="https://stileapp.com/library/"
          image={
            <StaticImage
              src="../../../../stile-shared/assets/images/resources/stile_library.png"
              alt="A closeup of a laptop showing the Stile Library"
            />
          }
        />

        <Card
          space={theme.space.xxs}
          title="Help centre"
          text="View help articles on all Stile app features."
          url="https://help.stileapp.com"
          image={
            <StaticImage
              src="../../../../stile-shared/assets/images/resources/help_centre.png"
              alt="A support staff member wearing a call headset and smiling"
            />
          }
        />
      </ResourceBlock>

      <ResourceBlock
        title="Teacher planning resources"
        text="An overview of all activities and practical activities in the Stile Library, complete
              with lesson-by-lesson curriculum mapping. Printed versions are also available on the
              Stile Shop."
      >
        <Card
          space={theme.space.xxs}
          title="NGSS Middle School Scope and Sequence"
          text="How Stile aligns to the NGSS for middle school and suggested scope and sequence."
          url="/resources/ngss-middle-school/"
          image={
            /* image is sufficiently described by captions, so alt text is not required */
            <StaticImage
              src="../../../../stile-shared/assets/images/resources/ngss_middle_school.png"
              alt=""
            />
          }
        />
        <Card
          space={theme.space.xxs}
          title="NGSS High School Scope and Sequence"
          text="How Stile aligns to the NGSS for high school and suggested scope and sequence."
          url="/resources/ngss-high-school/"
          image={
            /* image is sufficiently described by captions, so alt text is not required */
            <StaticImage
              src="../../../../stile-shared/assets/images/resources/ngss_high_school.png"
              alt=""
            />
          }
        />
      </ResourceBlock>

      <ResourceBlock title="Other resources">
        <Card
          title="The Stile Guide"
          text="The essential guide to supercharge your science teaching with Stile."
          url="/resources/stile-guide/"
          image={
            <StaticImage
              src="../../../../stile-shared/assets/images/resources/Stile_Guide_tile.jpg"
              alt="A stack of three copies of the Stile Guide"
            />
          }
        />
        <Card
          space={theme.space.xxs}
          title="Case study: Vaughn Middle School"
          text="Alex discusses how Stile has empowered her to bring her science classroom to life."
          url="http://stileapp.com/go/vms_case_study"
          image={
            <StaticImage
              src="../../../../stile-shared/assets/images/resources/VMS_case_study_tile.png"
              alt="A headshot of Alex holding several Stile X booklets and smiling at the camera, in front of blue school lockers"
            />
          }
        />
        <Card
          space={theme.space.xxs}
          title="Case study: Ingenium Middle School"
          text="Jovana discusses how she's leveraging Stile's customizability to engage all learners."
          url="https://stileapp.com/go/igms_case_study"
          image={
            <StaticImage
              src="../../../../stile-shared/assets/images/resources/IGMS_case_study_tile.jpg"
              alt="Jovana sitting in her classroom, smiling at the camera"
            />
          }
        />
        <Card
          space={theme.space.xxs}
          title="Case study: San Francisco Unified School District"
          text="Two teachers discuss their experience with Stile."
          url="http://stileapp.com/go/sfusd_case_study"
          image={
            <StaticImage
              src="../../../../stile-shared/assets/images/resources/sfusd_case_study.png"
              alt="An illustration showing a teacher at the board, with two students working on computers"
            />
          }
        />
        <Card
          space={theme.space.xxs}
          title="Virtual reality"
          text="View the VR experiences featured in our lessons."
          url="/other/vr/"
          image={
            <StaticImage
              src="../../../../stile-shared/assets/images/resources/virtual_reality.png"
              alt="A student holds a VR headset up to their eyes"
            />
          }
        />
      </ResourceBlock>

      <CtaBlock />
    </PageLayout>
  );
}

export default ResourcesPage;
